import * as React from 'react'
import Layout from '../components/layout';

const aboutPage = () => {
    return(
        <Layout pageTitle="About">
            <p>Not much is known about the author.</p>
            <p>Email: <a href="mailto:oldmatecannon@ohare.nz">oldmatecannon@ohare.nz</a></p>
        </Layout>
    );
}

export default aboutPage
